<template>
  <b-card-body :class="{'loading': loading}">
    <auction-report-completed-table
      :data="data"
      :total="total"
      :period="filter.period"
    />

  </b-card-body>
</template>

<script>
import AuctionReportCompletedTable
  from './AuctionReportCompletedTable';
import {customersRoutesInAuctionsStatistics} from '@/services/api';
export default {
  name: 'AuctionReportCompleted',
  components: {
    AuctionReportCompletedTable,
  },
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.customersRoutesInAuctionsStatistics(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.customersRoutesInAuctionsStatistics(this.filter);
  },
  methods: {
    async customersRoutesInAuctionsStatistics(data) {
      this.loading = true;
      const response = await customersRoutesInAuctionsStatistics(this.$store.state.user.ownerId, Object.assign(data));
      if (response && response.status === 200) {
        this.total= response.data.total;
        this.data = response.data.page;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped  lang="scss">
</style>
